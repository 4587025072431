import AppRouter from './components/AppRouter.js';
import './styles/styles.scss';

function App() {
    return (

        <AppRouter / >
    );
}

export default App;