import React from 'react'
import { Skills } from './Skills'
import { Timeline } from './Timeline'

export const SobreMi = () => {
    return (
        <div id='sobreMi' className='container mt-5 pt-3'>

            <Skills />
            <Timeline />

        </div>



    )
}




